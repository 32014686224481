import React, { useState, useEffect } from "react";
import {
  Modal,
  Progress,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  ButtonGroup,
  Switch,
} from "@nextui-org/react";
import {
  CheckCircleIcon,
  RocketLaunchIcon,
  PencilIcon,
  TruckIcon,
  ExclamationTriangleIcon,
  PlusIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/20/solid";
import { Spinner } from "@nextui-org/react";
import { useCourierData } from "../../contexts/courierHook";
import { useDeliverOrder } from "../../contexts/orderHook";
import usePackage from "../../contexts/packageHook";
import courierConfig from "../../configuration/courierConfig";

const flattenActiveServices = (courierData) => {
  return courierData.reduce((acc, courier) => {
    const activeServicesWithCourierInfo = courier.activeServices.map(
      (service) => ({
        ...service,
        customerNumber: courier.customerNumber,
        courierName: courier.courierName,
      })
    );
    return acc.concat(activeServicesWithCourierInfo);
  }, []);
};

export default function DeliverOrder({
  orderData,
  button,
  buttonStyle,
  deliverModalOpen,
  onModalClose,
}) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { courierData } = useCourierData();
  const {
    deliverOrder,
    getServicePoints,
    loading,
    error,
    success,
    path,
    servicePoints,
  } = useDeliverOrder();
  const {
    fetchPackage,
    packageData,
    loading: packageLoading,
    error: packageError,
  } = usePackage();

  const activeServices = courierData ? flattenActiveServices(courierData) : [];

  const [currentStep, setCurrentStep] = useState(null);
  const [returnShipment, setReturnShipment] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [selectedServicePoint, setSelectedServicePoint] = useState(null);
  const [packageCount, setPackageCount] = useState(1);
  const [weights, setWeights] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedNoSize, setSelectedNoSize] = useState(false);
  const [specialDimensions, setSpecialDimensions] = useState(false);
  const [specialDimensionsValues, setSpecialDimensionsValues] = useState({
    length: "",
    width: "",
    height: "",
  });
  const [currentPackageIndex, setCurrentPackageIndex] = useState(0);
  const [allWeightsFilled, setAllWeightsFilled] = useState(false);
  const [isPackagesTypes, setIsPackagesTypes] = useState(false);
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    if (deliverModalOpen) {
      onOpen();
    }
  }, [deliverModalOpen, onOpen]);

  useEffect(() => {
    if (button === "deliver") {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
  }, [button]);

  useEffect(() => {
    if (orderData && orderData.totalWeight) {
      const initialWeights = new Array(packageCount).fill("");
      initialWeights[0] = orderData.totalWeight.toString(); // Sätter vikten för paket 1 till orderData.totalWeight
      setWeights(initialWeights);
    }
  }, [orderData, packageCount]);

  useEffect(() => {
    // Hämta paketstorlekar
    if (selectedCourier && selectedCourier.packageTypes === true) {
      fetchPackage();
    }
  }, [selectedCourier]);

  useEffect(() => {
    if (packageData && packageData.length > 0) {
      setSizes(packageData);
      setIsPackagesTypes(true);
      setSpecialDimensionsValues({
        length: packageData[0].length || 0,
        width: packageData[0].width || 0,
        height: packageData[0].height || 0,
      });
    }
  }, [packageData]); // Kör när packageData har uppdaterats

  useEffect(() => {
    // Kolla om alla vikter är ifyllda
    const allFilled = weights.every(
      (weight) =>
        weight !== "" &&
        weight !== null &&
        weight !== undefined &&
        weight !== "0"
    );
    setAllWeightsFilled(allFilled);
  }, [weights]);

  useEffect(() => {
    // Skapa en array med tomma dimensioner för varje paket
    const initialDimensions = new Array(packageCount).fill({
      length: 0,
      width: 0,
      height: 0,
    });
    setDimensions(initialDimensions);
  }, [packageCount]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && currentStep === 3 && allWeightsFilled) {
        handleSubmit();
      }

      if (event.key === "Enter" && currentStep === 2) {
        handlePackageCountSubmit();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentStep, allWeightsFilled]);

  const handleShipmentType = (isReturn) => {
    setReturnShipment(isReturn);
    setCurrentStep(1); // Gå till nästa steg efter att knappen trycks på
  };

  const handleServicePointChange = (event) => {
    const selectedId = event.target.value;
    const selectedPoint = servicePoints.find(
      (point) => point.id === selectedId
    );
    setSelectedServicePoint(selectedPoint);
  };

  useEffect(() => {
    if (currentStep === 1.5 && servicePoints && servicePoints.length > 0) {
      setSelectedServicePoint(servicePoints[0]);
    }
  }, [servicePoints, currentStep]);

  const handleServiceSelect = async (service, courier) => {
    const orderId = orderData._id;
    setSelectedService(service);
    setSelectedCourier(courier);

    const courierBody = {
      productCode: service.productCode,
      payerCode: service.payerCode, // Anpassa om nödvändigt
      customerNumber: courier.customerNumber,
      courierId: courier.courierId,
    };

    if (
      service.type.target === "B2C" &&
      service.return === false &&
      courier.autoServicePoint === false
    ) {
      await getServicePoints(orderId, courierBody); // Hämta ombud när service är vald
      if (error) {
        console.log("Service points fetched");
        setCurrentStep(5);
      } else setCurrentStep(1.5);
    } else {
      setCurrentStep(2);
    }
  };

  const handlePackageCountSubmit = () => {
    const initialWeights = new Array(packageCount).fill("");
    if (orderData && orderData.totalWeight) {
      initialWeights[0] = orderData.totalWeight.toString(); // Sätter vikten för paket 1 till orderData.totalWeight
    }
    setWeights(initialWeights);
    setCurrentStep(3);
  };

  const handleServicePointSelect = () => {
    console.log(selectedServicePoint);
    setCurrentStep(2); // Gå vidare till nästa steg efter att ombud är valt
  };

  const handleWeightChange = (index, value) => {
    const updatedWeights = [...weights];
    updatedWeights[index] = value;
    setWeights(updatedWeights);
  };

  const handleWeightSubmit = () => {
    if (currentPackageIndex < packageCount - 1) {
      setCurrentPackageIndex(currentPackageIndex + 1);
    } else {
      console.log(
        `Order: ${orderData.orderId}, Service: ${selectedService.serviceName}, Packages: ${packageCount}, Weights: ${weights}`
      );
      onOpenChange(false); // Stäng modalen efter att processen är klar
    }
  };

  const handleSelectSize = (size) => {
    setSelectedSize(size); // Sätt den valda storleken i state
    setDimensions(size);
    setSpecialDimensions(false); // Stäng specialdimensionerna om en paketstorlek väljs
    setSelectedNoSize(false); // Nollställ boolean-värdet
  };

  const handleSelectedNoSize = () => {
    setSelectedNoSize(true);
    setSpecialDimensions(false);
    setSelectedSize(null); // Nollställ den valda storleken
    setDimensions({}); // Nollställ dimensions state
  };

  const handleSpecialDimensions = (event) => {
    setSpecialDimensions(true);
    setDimensions(specialDimensionsValues);
    setSelectedSize(null); // Nollställ den valda storleken
    setSelectedNoSize(false); // Nollställ boolean-värdet
  };

  const handleSpecialDimensionsChange = (field, value) => {
    // Uppdatera specialDimensionsValues och konvertera till numbers direkt
    setSpecialDimensionsValues((prev) => ({
      ...prev,
      [field]: Number(value) || 0, // Konvertera till number, och om tomt, sätt 0
    }));

    // Uppdatera dimensions state direkt med nya värden som numbers och som objekt
    setDimensions({
      ...specialDimensionsValues, // Nuvarande värden från state
      [field]: Number(value) || 0, // Uppdatera endast det fält som ändrats som number
    });
  };

  const resetCounter = () => {
    onOpenChange(false);
    setCurrentStep(1);
    setSelectedService(null);
    setPackageCount(1);
    setWeights([]);
    setCurrentPackageIndex(0);
  };

  const tryAgain = () => {
    setCurrentStep(1);
    setSelectedService(null);
    setPackageCount(1);
    setWeights([]);
    setCurrentPackageIndex(0);
  };

  const handleSubmit = () => {
    if (!selectedService || !selectedCourier || !orderData) return;
    setCurrentStep(4);

    const courier = {
      productCode: selectedService.productCode,
      payerCode: selectedService.payerCode, // Anpassa om nödvändigt
      customerNumber: selectedCourier.customerNumber,
      parcelCount: packageCount.toString(),
      courierId: selectedCourier.courierId,
      courierName: selectedCourier.courierName,
      autoServicePoint: selectedCourier.autoServicePoint || true,
      servicePoint: selectedServicePoint,
      orderId: orderData.orderId,
      returnShipment: selectedService.return,
      weights: weights,
      dimensions: [dimensions] || {},
    };
    const orderId = orderData._id;

    console.log(courier);
    console.log("servicepoint:", selectedServicePoint);
    deliverOrder(orderId, courier);
  };

  useEffect(() => {
    if (
      (success && path && currentStep === 4) ||
      (success && currentStep === 4)
    ) {
      onClose();
      if (path) {
        window.open(path, "_blank");
      }
      resetCounter();
    }
  }, [success, path]);

  useEffect(() => {
    if (error) {
      setCurrentStep(5);
    }
  }, [error]);

  return (
    <>
      {buttonStyle === "deliver" && (
        <button
          type="button"
          onClick={onOpen}
          className="inline-flex items-center gap-x-1.5 ml-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
          Leverera
        </button>
      )}
      {buttonStyle === "create" && (
        <button
          type="button"
          onClick={onOpen}
          className="inline-flex items-center gap-x-1.5 ml-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <PlusIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
          Skapa frakt
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onOpenChange={resetCounter}
        size="lg"
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {currentStep === 0 && "Välj typ"}
                {currentStep === 1 && "Välj fraktalternativ"}
                {currentStep === 1.5 && "Välj ombud"}
                {currentStep === 2 && "Mata in antal paket"}
                {currentStep === 3 &&
                  `Mata in vikt ${
                    isPackagesTypes ? "och storlek" : ""
                  } för paket ${currentPackageIndex + 1} av ${packageCount}`}
                {currentStep === 5 && "Fel"}
              </ModalHeader>
              <ModalBody>
                {currentStep === 0 && (
                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={() => handleShipmentType(false)}
                      className="inline-flex items-center gap-x-1.5 ml-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <PlusIcon
                        className="-mr-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      Utleverans
                    </button>
                    <button
                      type="button"
                      onClick={() => handleShipmentType(true)}
                      className="inline-flex items-center gap-x-1.5 ml-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <ArrowUturnLeftIcon
                        className="-mr-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      Retur
                    </button>
                  </div>
                )}
                {currentStep === 1 && (
                  <>
                    <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                      {courierData.map((courier, courierIndex) =>
                        courier.activeServices
                          .filter(
                            (service) =>
                              service.return === returnShipment &&
                              service.countries.includes(
                                orderData.shippingAddress.countryCode
                              ) // Lägg till land-filtrering
                          )
                          .map((service, serviceIndex) => (
                            <div
                              key={`${courier.courierName}-${serviceIndex}`}
                              className="relative flex items-center space-x-3 rounded-xl border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                              onClick={() =>
                                handleServiceSelect(service, courier)
                              }
                            >
                              <div className="flex-shrink-0">
                                <img
                                  className="h-14 w-14 rounded-full"
                                  src={
                                    courierConfig[courier.courierId].imageUrl
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="min-w-0 flex-1">
                                <a href="#" className="focus:outline-none">
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  <p className="text-sm font-medium text-gray-900">
                                    {service.serviceName}
                                  </p>
                                  {courier.testMode && ( // Kontrollera testMode på couriernivå
                                    <p className="text-sm text-gray-500">
                                      Testläge
                                    </p>
                                  )}
                                </a>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                    {courierData.every((courier) =>
                      courier.activeServices.every(
                        (service) =>
                          service.return === true ||
                          !service.countries.includes(
                            orderData.shippingAddress.countryCode
                          )
                      )
                    ) && (
                      <p className="text-center text-gray-500 mb-4">
                        Det finns inga fraktmetoder som passar den här
                        beställningen.
                      </p>
                    )}
                    {button === "deliver" && (
                      <>
                        <div className="relative">
                          <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                          >
                            <div className="w-full border-t border-gray-300" />
                          </div>
                          <div className="relative flex justify-center">
                            <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900">
                              Manuella fraktmetoder
                            </span>
                          </div>
                        </div>
                        <Button
                          color="primary"
                          variant="flat"
                          onPress={onClose}
                          isDisabled={!allWeightsFilled}
                        >
                          Markera endast som levererad
                        </Button>
                      </>
                    )}
                  </>
                )}
                {currentStep === 1.5 && (
                  <div>
                    <div>
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Ombud i närheten
                      </label>
                      {servicePoints && servicePoints.length > 0 ? (
                        <select
                          onChange={handleServicePointChange}
                          value={
                            selectedServicePoint
                              ? selectedServicePoint.id
                              : servicePoints[0]?.id
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          {servicePoints.map((point) => (
                            <option key={point.id} value={point.id}>
                              {`${point.name} (${point.street}, ${point.cityName})`}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p className="text-red-500">
                          Inga ombud tillgängliga eller felaktig
                          adressinformation.
                        </p>
                      )}
                      {selectedServicePoint && (
                        <div className="flex justify-between mt-2">
                          <p className="text-xs font-medium text-gray-600">
                            Avstånd fågelvägen:
                            <span className="ml-2 inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                              {`${selectedServicePoint.distance} ${
                                selectedServicePoint.distanceUnit || "km"
                              }`}
                            </span>
                          </p>
                          <p className="text-xs font-medium text-gray-600">
                            Körsträcka:
                            <span className="ml-2 inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                              {`${selectedServicePoint.routeDistance} ${
                                selectedServicePoint.distanceUnit || "km"
                              }`}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <Button
                      color="primary"
                      onClick={handleServicePointSelect}
                      className="mt-4"
                    >
                      Nästa
                    </Button>
                  </div>
                )}
                {currentStep === 2 && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Antal paket (max: 1 st)
                    </label>
                    <input
                      type="number"
                      value={packageCount}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setPackageCount(value > 1 ? 1 : value);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <Button
                      color="primary"
                      onClick={handlePackageCountSubmit}
                      className="mt-4"
                    >
                      Nästa
                    </Button>
                  </div>
                )}
                {currentStep === 3 && (
                  <>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Vikt (kg) för paket {currentPackageIndex + 1}
                      </label>
                      <input
                        type="number"
                        step="0.1"
                        value={weights[currentPackageIndex] || ""}
                        onChange={(e) =>
                          handleWeightChange(
                            currentPackageIndex,
                            e.target.value
                          )
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      {isPackagesTypes && (
                        <>
                          <div className="mt-3 relative">
                            <div
                              aria-hidden="true"
                              className="absolute inset-0 flex items-center"
                            >
                              <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center">
                              <span className="bg-white px-2 text-sm text-gray-500">
                                Paketmallar
                              </span>
                            </div>
                          </div>
                          <div className="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-3">
                            {sizes.map((size) => (
                              <div
                                key={size._id}
                                onClick={() => handleSelectSize(size)}
                                className={`relative flex items-center space-x-3 rounded-xl border bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 
                            ${
                              selectedSize &&
                              selectedSize._id === size._id &&
                              !specialDimensions
                                ? "border-indigo-500 ring-1 ring-indigo-500"
                                : "border-gray-300"
                            }`}
                              >
                                <div className="min-w-0 flex-1">
                                  <span
                                    className="absolute inset-0"
                                    aria-hidden="true"
                                  />
                                  <p className="flex justify-center text-sm font-bold text-gray-900">
                                    {size.title}
                                  </p>
                                  <p className="flex justify-center text-sm text-gray-500">
                                    {size.length}x{size.width}x{size.height}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="mt-5 grid grid-cols-1 gap-3">
                            <div
                              className={`relative flex items-center space-x-3 rounded-xl border bg-white px-6 py-5 shadow-sm hover:border-gray-400 
                          ${
                            specialDimensions
                              ? "border-indigo-500 ring-1 ring-indigo-500"
                              : "border-gray-300"
                          }`}
                              onClick={handleSpecialDimensions}
                            >
                              <div className="min-w-0 flex-1">
                                <p className="flex justify-center text-sm font-bold text-gray-900">
                                  Specialmått
                                </p>
                                {specialDimensions && (
                                  <div className="mt-3 grid grid-cols-3 gap-3">
                                    <div className="col-span-1">
                                      <label
                                        htmlFor="length"
                                        className="block text-sm font-medium leading-6 text-gray-500"
                                      >
                                        Längd (cm)
                                      </label>
                                      <input
                                        id="length"
                                        name="length"
                                        defaultValue={sizes[0].length || 0}
                                        onChange={(e) =>
                                          handleSpecialDimensionsChange(
                                            "length",
                                            e.target.value
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        type="number"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="col-span-1">
                                      <label
                                        htmlFor="width"
                                        className="block text-sm font-medium leading-6 text-gray-500"
                                      >
                                        Bredd (cm)
                                      </label>
                                      <input
                                        id="width"
                                        name="width"
                                        defaultValue={sizes[0].width || 0}
                                        onChange={(e) =>
                                          handleSpecialDimensionsChange(
                                            "width",
                                            e.target.value
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        type="number"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="col-span-1">
                                      <label
                                        htmlFor="height"
                                        className="block text-sm font-medium leading-6 text-gray-500"
                                      >
                                        Höjd (cm)
                                      </label>
                                      <input
                                        id="height"
                                        name="height"
                                        defaultValue={sizes[0].height || 0}
                                        onChange={(e) =>
                                          handleSpecialDimensionsChange(
                                            "height",
                                            e.target.value
                                          )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        type="number"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              onClick={handleSelectedNoSize}
                              className={`relative flex items-center space-x-3 rounded-xl border bg-white px-6 py-5 shadow-sm hover:border-gray-400 cursor-pointer
                              ${
                                selectedNoSize
                                  ? "border-indigo-500 ring-1 ring-indigo-500"
                                  : "border-gray-300"
                              }`} // Villkorlig rendering av bordern
                            >
                              <div className="min-w-0 flex-1">
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                />
                                <p className="flex justify-center text-sm font-bold text-gray-900">
                                  Ingen paketdimension
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {currentPackageIndex < packageCount - 1 && (
                      <Button
                        color="primary"
                        onClick={handleWeightSubmit}
                        className="mt-4"
                      >
                        Nästa paket
                      </Button>
                    )}
                  </>
                )}
                {currentStep === 4 && (
                  <>
                    <label className="block text-sm font-medium text-gray-700">
                      Bokar frakt...
                    </label>
                    <Progress
                      size="sm"
                      isIndeterminate
                      aria-label="Loading..."
                      className="max-w-md"
                    />
                  </>
                )}
                {currentStep === 5 && (
                  <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">
                          Ett fel uppstod
                        </h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>{error}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ModalBody>

              <ModalFooter>
                {currentStep === 1 && loading && <Spinner />}
                {currentStep === 1 && button !== "deliver" && (
                  <div>
                    <Button
                      color="primary"
                      variant="bordered"
                      onPress={() => setCurrentStep(0)}
                    >
                      Tillbaka
                    </Button>
                  </div>
                )}
                <div>
                  <Button color="danger" variant="light" onPress={resetCounter}>
                    Avbryt
                  </Button>
                </div>
                {currentStep === 3 && (
                  <Button
                    color="primary"
                    onPress={handleSubmit}
                    isDisabled={!allWeightsFilled}
                  >
                    Skapa fraktsedel
                  </Button>
                )}
                {currentStep === 5 && (
                  <Button
                    color="primary"
                    onPress={tryAgain}
                    isDisabled={!allWeightsFilled}
                  >
                    Testa igen
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
