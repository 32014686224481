import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  BarChart,
  PieChart,
  Bar,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useEffect } from "react";
import { useStats } from "../../contexts/dashboardHook";
import ShippingPieChart from "./stats";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const { fetchStats, stats: dashStats, loading, error } = useStats();

  const deliveredChangeType =
    dashStats?.percentageDifference >= 0 ? "increase" : "decrease";

  const stats = [
    {
      name: "Utlevererat idag",
      stat: `${dashStats?.totalValueSentToday || 0} kr`,
      previousStat: `${dashStats?.totalValueSentYesterday || 0} kr`,
      prefix: "igår",
      change: `${dashStats?.percentageDifference || 0} %`,
      changeType: deliveredChangeType,
    },
    {
      name: "Paket senaste 7 dagar",
      stat: `${dashStats?.totalShipments || 0} st`,
      previousStat: "",
      prefix: "",
      change: "",
      changeType: null,
    },
    {
      name: "Utförda upphämtningar",
      stat: `${dashStats?.completedPickups || 0} st`,
      previousStat: "",
      prefix: "",
      change: "",
      changeType: null,
    },
    {
      name: "Kommande upphämtningar",
      stat: `${dashStats?.awaitingPickup || 0} st`,
      previousStat: "",
      prefix: "",
      change: "",
      changeType: null,
    },
  ];

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <>
      <div className="flex flex-col sm:flex-row mt-4 mx-auto max-w-7xl sm:space-x-4 items-stretch">
        <div className="w-full sm:basis-3/5 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            {/* Left column content goes here */}
            <div className="bg-white pb-5 sm:px-6">
              <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-4">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Översikt (beta)
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Statistik för de senaste 7 dagarna{" "}
                  </p>
                </div>
                <div className="ml-4 mt-4 flex-shrink-0">
                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Hantera
                  </button>
                </div>
              </div>
            </div>
            <BarChart
              width={600}
              height={300}
              data={dashStats?.weekData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />

              <XAxis dataKey="day" />

              <YAxis
                yAxisId="left"
                label={{
                  value: "",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                label={{
                  value: "Antal paket",
                  angle: -90,
                  position: "insideRight",
                }}
              />

              <Tooltip
                formatter={(value, name) => {
                  if (name === "Utlev. värde") {
                    return [`${value} SEK`, name];
                  } else if (name === "Antal kolli") {
                    return [`${value} st`, name];
                  }
                  return [value, name];
                }}
              />
              <Legend />

              <Bar
                dataKey="sentOrderValue"
                fill="#8884d8"
                name="Utlev. värde"
                yAxisId="left"
              />
              <Bar
                dataKey="shipments"
                fill="#82ca9d"
                name="Antal kolli"
                yAxisId="right"
              />
            </BarChart>
          </div>
        </div>
        <div className="w-full sm:basis-2/5 overflow-hidden rounded-lg bg-white shadow mt-4 sm:mt-0">
          <div className="px-4 py-5 sm:p-6">
            {/* Right column content goes here */}
            <div className="bg-white sm:px-6">
              <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-4">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Mest använda fraktmetoder
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Dina vanligaste fraktmetoder de senaste 7 dagarna
                  </p>
                </div>
              </div>
              <div className="w-full h-64 mt-4">
                <ShippingPieChart data={dashStats?.topShippingMethods} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-4 mx-auto max-w-7xl">
        {stats.map((item) => (
          <div
            key={item.name}
            className="overflow-hidden rounded-xl bg-white shadow px-4 py-5 sm:p-6"
          >
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  {" "}
                  {item.prefix} {item.previousStat}
                </span>
              </div>
              {item.changeType !== null && (
                <div
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-800"
                      : "text-red-800",
                    "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                  )}
                >
                  {item.changeType === "increase" ? (
                    <ArrowUpIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  )}
                  <span className="sr-only">
                    {" "}
                    {item.changeType === "increase"
                      ? "Increased"
                      : "Decreased"}{" "}
                    by{" "}
                  </span>
                  {item.change}
                </div>
              )}
            </dd>
          </div>
        ))}
      </div>
    </>
  );
}
