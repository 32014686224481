import { useState, useEffect } from 'react';
import { useUpdate } from './updateContext';
import toast, { Toaster } from 'react-hot-toast';


function useFetchOrders() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [metaData, setMetaData] = useState(null);

    // Modifierad för att acceptera en sökterm
    const fetchOrders = async (params = {}) => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('site');
            const url = new URL(process.env.REACT_APP_FETCH_ORDERS_URL);

            Object.keys(params).forEach(key => {
                if (params[key] !== undefined && params[key] !== null) {
                    if (typeof params[key] === 'object') {
                        url.searchParams.append(key, JSON.stringify(params[key]));
                    } else {
                        url.searchParams.append(key, params[key]);
                    }
                }
            });


            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = await response.json();
            if (response.status == 400) {
                return
            } else if (!response.ok) {
                let errorMessage = 'Ett fel inträffade';
                // Lägger till mer specifik felhantering om nödvändigt
                throw new Error(errorMessage);
            }

            const { orders, ...metaData } = data;

            setOrderData(orders);
            setMetaData(metaData)
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const resetError = () => setError(null);

    // Returnerar fetchOrders så att den kan anropas med en sökterm
    return { fetchOrders, loading, error, orderData, metaData, resetError };
}

function useGetOrder(orderId) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const { updateCount } = useUpdate();

    useEffect(() => {
        const getOrder = async () => {
            if (!orderId) return; // Kontrollera om orderId är definierad

            setLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem('site');
                // Inkluderar searchTerm i anropet, anpassa enligt din API-design
                const url = new URL(`${process.env.REACT_APP_FETCH_ORDERS_URL}/${orderId}`);

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const order = await response.json();
                if (!response.ok) {
                    let errorMessage = 'Ett fel inträffade';
                    // Lägger till mer specifik felhantering om nödvändigt
                    throw new Error(errorMessage);
                }

                setOrderData(order);
            } catch (error) {
                setError(error.message);
                toast.error('Fel vid hämtning av beställning');
            } finally {
                setLoading(false);
            }
        }
        getOrder();
    }, [orderId, updateCount]);

    return { loading, error, orderData, setOrderData };

}

function useAddOrderComment() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { setUpdateCount } = useUpdate();

    const addComment = async (orderId, comment) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        // Kommentarens längd validering
        if (!comment.trim()) {
            setLoading(false);
            setError('Kommentaren kan inte vara tom.');
            toast('Eh... Skrev du något? Det verkar som att kommentaren är tom!', {
                icon: '🤨',
            });
            return { success: false, error: 'Kommentaren kan inte vara tom.' };
        }
        if (comment.length > 1000) {
            setLoading(false);
            setError('Kommentaren kan inte vara längre än 1000 tecken.');
            toast('Wow, det där var en hel roman! Korta ner det till 1000 tecken.', {
                icon: '📖'
            });
            return { success: false, error: 'Kommentaren kan inte vara längre än 1000 tecken.' };
        }

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_ADD_COMMENT_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ orderId, comment })
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while adding comment'
            }
            setSuccess(true);
            return { success: true };
        } catch (error) {
            setError(error.message);
            toast.error('Tekniken är inte på vår sida just nu, ladda om sidan och försök igen.');
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    };
    return { addComment, loading, error, success };
}

function useDeliverOrder() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [path, setPath] = useState(null);
    const [servicePoints, setServicePoints] = useState(null);
    const { setUpdateCount } = useUpdate();

    const deliverOrder = async (orderId, courier) => {
        setLoading(true);
        setError(null);
        setSuccess(false);
        setPath(null);

        // validering?

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_DELIVER_ORDER_URL}/${orderId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ courier })
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while delivering order'
            }
            setSuccess(true);
            setPath(data.path);
            toast.success('Frakten bokades.');
            setUpdateCount(prevCount => prevCount + 1)
            return { success: true, path: data.path };
        } catch (error) {
            setError(error.message);
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    };

    const getServicePoints = async (orderId, courier) => {
        setLoading(true);
        setError(null);
        setSuccess(false);


        // validering?

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_SERVICEPOINTS_URL}/${orderId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ courier })
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while delivering order'
            }
            setSuccess(true);
            setServicePoints(data)
            return { success: true, servicePoints: data };
        } catch (error) {
            setError(error.message);
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    }
    return { deliverOrder, getServicePoints, loading, error, success, path, servicePoints };
}

function useUpdateOrder() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { setUpdateCount } = useUpdate();

    const setExternalOrder = async (orderId, externalUserIds) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const body = {
                externalUserIds: externalUserIds
            }
            const token = localStorage.getItem('site');
            const response = await fetch(`${process.env.REACT_APP_SET_EXTERNAL_ORDER_URL}/${orderId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(body)
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while updating order'
            }
            setSuccess(true);
            toast.success('Ordern uppdaterades.');
            setUpdateCount(prevCount => prevCount + 1)
            return { success: true };
        } catch (error) {
            setError(error.message);
            toast.error('Ett fel uppstod.');
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    };
    return { setExternalOrder, loading, error, success };
}


export default useFetchOrders;
export { useGetOrder, useAddOrderComment, useDeliverOrder, useUpdateOrder };

