import { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';


function useExternal() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [deleted, setDeleted] = useState(null);
    const [externalEnvironments, setExternalEnvironments] = useState(null);
    const [oneTimeCode, setOneTimeCode] = useState(null);

    const fetchExternal = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_EXTERNAL_ENVIRONMENTS_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while fetching external'
            }
            setExternalEnvironments(data.externalEnvironments);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av data');
        } finally {
            setLoading(false);
        }
    }

    const getCode = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_GENERATE_ACCESS_CODE_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message) || 'An error occurred while fetching external'
            }
            setOneTimeCode(data);
        } catch (error) {
            setError(error.message);
            toast.error('Fel vid hämtning av data');
        } finally {
            setLoading(false);
        }
    }

    const addExternal = async (accessCode, externalUserId) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_ADD_EXTERNAL_ENVIRONMENT_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ accessCode, externalUserId })
            });

            const data = await response.json();
            if (!response.ok) {
                switch (response.status) {
                    case 400:
                        throw new Error('Du kan inte lägga till dig själv, eller samma användare två gånger.');
                    case 401:
                        throw new Error('Engångskoden är felaktig eller utgången.');
                    case 404:
                        throw new Error('Användaren kunde inte hittas.');
                    case 500:
                        throw new Error('Ett serverfel inträffade. Försök igen senare.');
                    default:
                        throw new Error('Ett oväntat fel inträffade.');
                }
            }
            setSuccess(true);
            toast.success('Extern miljö kopplad!');
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    const removeExternal = async (externalUserId) => {
        setLoading(true);
        setError(null);
        setDeleted(false);

        try {
            const token = localStorage.getItem('site');
            const response = await fetch(process.env.REACT_APP_REMOVE_EXTERNAL_ENVIRONMENT_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ externalUserId })
            });

            const data = await response.json();
            if (!response.ok) {
                switch (response.status) {
                    case 400:
                        throw new Error('Du kan inte ta bort dig själv, eller samma användare två gånger.');
                    case 401:
                        throw new Error('Engångskoden är felaktig eller utgången.');
                    case 404:
                        throw new Error('Användaren kunde inte hittas.');
                    case 500:
                        throw new Error('Ett serverfel inträffade. Försök igen senare.');
                    default:
                        throw new Error('Ett oväntat fel inträffade.');
                }
            }
            setDeleted(true);
            toast.success('Extern miljö avaktiverad och borttagen!');
        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    return { fetchExternal, getCode, addExternal, removeExternal, loading, success, error, deleted, externalEnvironments, oneTimeCode };
}

export default useExternal;