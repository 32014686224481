import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  UserCircleIcon as UserIcon,
} from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Spinner, Switch, Code } from "@nextui-org/react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { XMarkIcon, KeyIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import useExternal from "../../contexts/externalHook.js";

const statuses = {
  accepted: "text-green-700 bg-green-50 ring-green-600/20",
  rejected: "text-gray-600 bg-gray-50 ring-gray-500/10",
  undefined: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ExternalEnvironments({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                      Externa miljöer
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-500">
                      Hantera externa Flowtrade-miljöer för att kunna samarbeta
                      med andra organisationer.
                    </p>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <Environments />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const CodeCountdown = ({ expiresAt, code, userId }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    if (!expiresAt || !code) return;

    const calculateTimeLeft = () => {
      const now = new Date();
      const expirationTime = new Date(expiresAt);
      const difference = expirationTime - now;

      if (difference <= 0) {
        setTimeLeft("Utgången");
        clearInterval(timer);
        return;
      }

      const minutes = Math.floor(difference / 1000 / 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft(`${minutes}m ${seconds}s`);
    };

    // Uppdatera varje sekund
    const timer = setInterval(calculateTimeLeft, 1000);

    // Rensa intervallet när komponenten avmonteras
    return () => clearInterval(timer);
  }, [expiresAt]);

  //userId, timeLeft, code

  return (
    <div className="lg:col-start-3 lg:row-end-1">
      <h2 className="sr-only">One-time code</h2>
      <div className="rounded-lg bg-gray-50 ring-1 shadow-xs ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pt-6 pl-6">
            <dt className="text-sm/6 font-semibold text-gray-900">
              Engångskod
            </dt>
            <dd className="mt-1 text-base font-semibold text-gray-900">
              {code}
            </dd>
          </div>
          <div className="flex-none self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-green-600/20 ring-inset">
              {timeLeft}
            </dd>
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 p-6">
            <dt className="flex-none">
              <span className="sr-only">User</span>
              <UserIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 font-medium text-gray-900">{userId}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

function Environments() {
  const [isAddmode, setIsAddmode] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const {
    fetchExternal,
    getCode,
    addExternal,
    removeExternal,
    externalEnvironments,
    oneTimeCode,
    loading,
    success,
    error,
    deleted,
  } = useExternal();

  useEffect(() => {
    fetchExternal();
    getCode();
  }, []);

  useEffect(() => {
    if (deleted === true) {
      fetchExternal();
    }
  }, [deleted]);

  useEffect(() => {
    if (success === true) {
      setIsAddmode(false);
      fetchExternal();
    }
  }, [success]);

  const handleRemoveEnv = (id) => {
    removeExternal(id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBtnDisabled(true);

    const formData = {
      accessCode: event.target.code.value,
      externalUserId: event.target.uid.value,
    };

    try {
      await addExternal(formData.accessCode, formData.externalUserId);
      setBtnDisabled(false);
    } catch (error) {
      console.error("Error adding external environment:", error);
      setBtnDisabled(false);
    }
  };

  return (
    <>
      {isAddmode === false && (
        <>
          <div className="border-b border-gray-200 pb-5 flex sm:items-center justify-between">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setIsAddmode(true)}
            >
              <PlusIcon className="h-5 w-5 mr-1" /> Lägg till konto
            </button>
            {loading && <Spinner className="ml-4" />}
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setIsAddmode("code")}
            >
              <KeyIcon className="h-5 w-5 mr-1" /> Se mina uppgifter
            </button>
          </div>
          <ul role="list" className="divide-y divide-gray-100">
            {loading === false &&
              externalEnvironments?.map((env) => (
                <li
                  key={env._id}
                  className="flex items-center justify-between gap-x-6 py-5"
                >
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {env.companyName}
                      </p>
                      {env.status && (
                        <p
                          className={classNames(
                            statuses[env.status],
                            "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                          )}
                        >
                          {env.status === "accepted"
                            ? "Aktiv"
                            : "Borttagen av användaren"}
                        </p>
                      )}
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      {env.companyName && (
                        <p className="whitespace-nowrap">
                          Tillagd: {env.createdAt.split("T")[0]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <Menu as="div" className="relative flex-none">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <button
                            className="block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left data-[focus]:bg-gray-50"
                            onClick={() => handleRemoveEnv(env.userId)}
                          >
                            Ta bort
                            <span className="sr-only">, {env.companyName}</span>
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </li>
              ))}
          </ul>
        </>
      )}
      {isAddmode === true && (
        <>
          <form onSubmit={handleSubmit}>
            <div>
              <p className="text-sm text-gray-900">
                För att lägga till en annan organisation för samarbete, ange
                deras uppgifter, vilka är synliga i deras inställningar under
                "Se mina uppgifter".
              </p>
            </div>
            <div className="mt-2">
              <label
                htmlFor="uid"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Engångskod
              </label>
              <div className="mt-2 w-1/2">
                <input
                  type="text"
                  name="code"
                  id="code"
                  placeholder="12345678"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-2">
              <label
                htmlFor="uid"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Användar-ID
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="uid"
                  id="uid"
                  placeholder="1dsa1d321321d32sa1d"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={() => setIsAddmode(false)}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Avbryt
              </button>
              <button
                type="submit"
                className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                  btnDisabled
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
                }`}
                disabled={btnDisabled}
              >
                Lägg till
              </button>
            </div>
          </form>
        </>
      )}
      {isAddmode === "code" && (
        <>
          <div className="space-y-2">
            {oneTimeCode.code && (
              <CodeCountdown
                expiresAt={oneTimeCode.expiresAt}
                code={oneTimeCode.code}
                userId={oneTimeCode.userId}
              />
            )}
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={() => setIsAddmode(false)}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Tillbaka
            </button>
          </div>
        </>
      )}
    </>
  );
}

export { ExternalEnvironments };
