import React, { useState, useEffect } from "react";
import {
  Modal,
  Select,
  SelectItem,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Avatar,
} from "@nextui-org/react";
import { useCourierUpdate } from "../../contexts/courierHook";
import { useUpdate } from "../../contexts/updateContext";
import AddIntegrationMessage from "./addIntegrationError";
import toast from "react-hot-toast";

const platforms = [
  {
    label: "DHL Freight",
    value: "1",
    image:
      "https://flowtrade.s3.eu-north-1.amazonaws.com/assets/logos/dhl-logo-500x500.png",
  },
  {
    label: "Bring",
    value: "2",
    image:
      "https://flowtrade.s3.eu-north-1.amazonaws.com/assets/logos/bring-logo-500x500.png",
  },
];

const platformInputs = (handleInputChange, inputValues) => ({
  1: (
    <>
      <div className="mt-2">
        <label
          htmlFor="customerNumber"
          className="block text-sm font-medium leading-5 pb-1 text-gray-900"
        >
          Kundnummer
        </label>
        <input
          type="text"
          id="customerNumber"
          onChange={handleInputChange}
          value={inputValues.customerNumber || ""}
          placeholder="Ditt DHL-kundnummer"
          className="mt-1 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-5"
        />
      </div>
      <div className="mt-2">
        <label
          htmlFor="courierName"
          className="block text-sm font-medium leading-5 pb-1 text-gray-900"
        >
          Valfri benämning
        </label>
        <input
          type="text"
          name="name"
          onChange={handleInputChange}
          value={inputValues.courierName || ""}
          id="courierName"
          className="mt-1 block w-full rounded-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-5"
          placeholder="DHL Freight"
        />
      </div>
    </>
  ),
  2: (
    <>
      <div className="mt-2">
        <label
          htmlFor="customerNumber"
          className="block text-sm font-medium leading-5 pb-1 text-gray-900"
        >
          Mybring UID
        </label>
        <input
          type="text"
          id="uid"
          onChange={handleInputChange}
          value={inputValues.uid || ""}
          placeholder="example@example.com"
          className="mt-1 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-5"
        />
      </div>
      <div className="mt-2">
        <label
          htmlFor="customerNumber"
          className="block text-sm font-medium leading-5 pb-1 text-gray-900"
        >
          Mybring API-key
        </label>
        <input
          type="text"
          id="apiKey"
          onChange={handleInputChange}
          value={inputValues.apiKey || ""}
          placeholder="1234abc-abcd-1234-5678-abcd1234abcd"
          className="mt-1 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-5"
        />
      </div>
      <div className="mt-2">
        <label
          htmlFor="customerNumber"
          className="block text-sm font-medium leading-5 pb-1 text-gray-900"
        >
          Kundnummer
        </label>
        <input
          type="text"
          id="customerNumber"
          onChange={handleInputChange}
          value={inputValues.customerNumber || ""}
          placeholder="2000123456"
          className="mt-1 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-5"
        />
      </div>
      <div className="mt-2">
        <label
          htmlFor="courierName"
          className="block text-sm font-medium leading-5 pb-1 text-gray-900"
        >
          Valfri benämning
        </label>
        <input
          type="text"
          name="name"
          onChange={handleInputChange}
          value={inputValues.courierName || ""}
          id="courierName"
          className="mt-1 block w-full rounded-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-5"
          placeholder="Bring"
        />
      </div>
    </>
  ),
  // Add more platforms and their inputs as needed
});

const divider = (
  <div className="relative">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex justify-center">
      <span className="bg-white px-2 text-sm text-gray-500">Fortsätt</span>
    </div>
  </div>
);

export default function AddCourierModal() {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const { setUpdateCount } = useUpdate();

  const { addCourier, loading, error, success, resetError } =
    useCourierUpdate();

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setInputValues((prev) => ({ ...prev, [id]: value }));
    resetError();
  };

  const inputs = platformInputs(handleInputChange, inputValues)[
    selectedPlatform
  ];

  const handleSubmit = async (event) => {
    const formData = {
      ...inputValues,
      courierId: selectedPlatform,
    };

    try {
      await addCourier(formData);
    } catch (error) {
      toast.error(error.message || "Okänt fel");
    }
  };

  useEffect(() => {
    //behöver ha en success-objekt från hooken
    if (success) {
      onClose();
    }
  }, [success]);

  const onCloseAndReset = () => {
    onClose(); // Anta att onOpen är din funktion för att öppna modalen
    setSelectedPlatform(null); // Återställer selectedPlatform state till null
    resetError();
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Lägg till transportör
      </button>
      <Modal isOpen={isOpen} onClose={onCloseAndReset} placement="auto">
        <ModalContent>
          <ModalHeader className="...">Lägg till transportör</ModalHeader>
          <ModalBody>
            <div className="flex justify-center">
              <Select
                label="Transportör"
                placeholder="Välj transportör"
                className="max-w"
                onChange={(e) => setSelectedPlatform(e.target.value)}
              >
                {platforms.map((platform) => (
                  <SelectItem
                    key={platform.value}
                    value={platform.value}
                    startContent={
                      <Avatar
                        alt={platform.label}
                        className="w-6 h-6"
                        src={platform.image}
                      />
                    }
                  >
                    {platform.label}
                  </SelectItem>
                ))}
              </Select>
            </div>
            {selectedPlatform && divider}
            {/* Render platform-specific input fields */}
            {selectedPlatform &&
              platformInputs(handleInputChange, inputValues)[selectedPlatform]}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="flat" onPress={onClose}>
              Avbryt
            </Button>
            <Button
              color="primary"
              onPress={handleSubmit}
              isLoading={loading}
              isDisabled={selectedPlatform === "3"}
            >
              Validera
            </Button>
          </ModalFooter>
          {error && <AddIntegrationMessage message={error} />}
        </ModalContent>
      </Modal>
    </>
  );
}
