import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Switch,
  useDisclosure,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { useLocationData } from "../../contexts/integrationHook";
import useIntegrationUpdate from "../../contexts/integrationHook";
import toast from "react-hot-toast";
import {
  ExclamationTriangleIcon,
  DocumentDuplicateIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";

function NoLocation() {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Välj en location
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>Integrationen måste ha en kopplad location för att fungera.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function NoSetup() {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Konfigurera webhook
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              Integrationen måste ha en konfigurerad webhook för att fungera.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function IntegrationSettings({ integration }) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { locationData, loading } = useLocationData(integration._id);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isActive, setIsActive] = useState(integration.active);
  const {
    updateIntegration,
    editIntegration,
    integrationData,
    error,
    success,
    resetError,
  } = useIntegrationUpdate();

  const [selectedLocation, setSelectedLocation] = useState([]);
  const link = `https://api.flowtrade.se/webhook/nyehandel/orders/updated/${integration._id}`;

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);

  const handleSelectChange = (value) => {
    // Antag att 'value' är en array av 'productCode' från de valda alternativen.
    setSelectedLocation(
      locationData?.filter((location) => value.includes(location.id))
    );
    console.log("Selected values:", value);
    console.log("Filtered locations:", selectedLocation);
  };

  const handleConfirmationClick = () => {
    setIsConfirmed(true);
  };

  const handleIsActiveChange = (event) => {
    const newIsActiveValue = event.target.checked; // Hämtar 'checked'-värdet från switchen
    console.log("Nytt värde för isActive:", newIsActiveValue); // Loggar det korrekta värdet
    setIsActive(newIsActiveValue); // Uppdaterar tillståndet med det korrekta värdet
  };

  useEffect(() => {
    if (integration.location) {
      const locationDetails = integration.location.map((location) => ({
        id: location.id,
        name: location.name, // Antag att det här värdet finns tillgängligt.
      }));
      setSelectedLocation(locationDetails);
    }
  }, [integration.location]);

  const handleSubmitUpdate = async () => {
    try {
      const formData = {
        _id: integration._id,
        location: selectedLocation,
        active: isActive,
        ...(isConfirmed && { setup: true }),
      };
      console.log(formData);
      await editIntegration(formData);
      onClose();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message || "Okänt fel vid uppdatering");
    }
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="text-indigo-600 hover:text-indigo-900"
      >
        Hantera
      </button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Hantera integration
              </ModalHeader>
              <ModalBody>
                <Switch
                  defaultSelected={integration?.active}
                  onChange={handleIsActiveChange}
                >
                  {isActive ? "Aktiv" : "Inaktiv"}
                </Switch>
                <p className="text-small text-default-500">
                  När integrationen är inaktiv hämtas inte beställningar till
                  systemet.
                </p>
                {integration?.platformId === "3" && (
                  <>
                    <Select
                      label="Location som används"
                      selectionMode="multiple"
                      className="w-full"
                      placeholder="Välj en location"
                      defaultSelectedKeys={selectedLocation?.map(
                        (location) => location.id
                      )}
                      onChange={(e) => handleSelectChange(e.target.value)}
                    >
                      {locationData?.map((location) => (
                        <SelectItem key={location.id} value={location.id}>
                          {location.name}
                        </SelectItem>
                      ))}
                    </Select>
                    {selectedLocation?.length === 0 && <NoLocation />}
                    <p className="text-small text-default-500">
                      Locations används om du har olika lager. Du hanterar
                      locations i din Shopify-admin.
                    </p>
                  </>
                )}
                {integration.platformId === "4" && (
                  <>
                    <div className="relative">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center">
                        <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900">
                          Webhook
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <label
                        htmlFor="url"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        URL
                      </label>
                      <input
                        type="text"
                        value={link}
                        readOnly
                        className="border border-gray-300 rounded-md p-2 text-sm w-full bg-gray-100 text-gray-800 cursor-text"
                        onClick={(e) => e.target.select()}
                      />
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(link);
                          toast.success("Kopierade länken!");
                        }}
                        className="rounded-full bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <DocumentDuplicateIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </button>
                    </div>
                    {integration.setup !== true && <NoSetup />}
                    <p className="text-small text-default-500">
                      I Nyehandel, skapa en ny webhook till URL:en ovan. Lämna
                      fältet för autentisering tomt. Markera alla events,
                      förutom "Order godkänd".
                    </p>
                    <p className="text-small text-default-500">
                      Dela inte URL:en med obehöriga.
                    </p>
                    {integration.setup !== true && (
                      <div className="relative flex justify-center items-center gap-5">
                        <p className="text-sm font-medium text-gray-500">
                          Bekräfta tillagd webhook
                        </p>
                        <Button
                          color="primary"
                          size="sm"
                          onClick={handleConfirmationClick}
                          isDisabled={isConfirmed}
                        >
                          OK
                        </Button>
                        {isConfirmed && (
                          <>
                            <p className="text-sm font-medium text-gray-900"></p>
                            <CheckIcon
                              aria-hidden="true"
                              className="ml-1 h-5 w-5 text-green-500"
                            />
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Avbryt
                </Button>
                <Button color="primary" onPress={handleSubmitUpdate}>
                  Spara
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
