import React, { useState, useEffect } from "react";
import {
  Modal,
  Select,
  SelectItem,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Avatar,
  Code,
} from "@nextui-org/react";
import useIntegrationUpdate from "../../contexts/integrationHook";
import { useUpdate } from "../../contexts/updateContext";
import toast from "react-hot-toast";
import AddIntegrationMessage from "./addIntegrationError";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

const platforms = [
  {
    label: "Shopify (manuell)",
    value: "1",
    image:
      "https://freelogopng.com/images/all_img/1655837345shopify-png-icon.png",
  },
  {
    label: "Shopify",
    value: "3",
    image:
      "https://freelogopng.com/images/all_img/1655837345shopify-png-icon.png",
  },
  {
    label: "Nyehandel",
    value: "4",
    image:
      "https://flowtrade.s3.eu-north-1.amazonaws.com/assets/logos/nyehandel-logo-500x500.png",
  },
];

const platformInputs = (handleInputChange, inputValues) => ({
  // Assuming "1" is the value for Shopify
  1: (
    <>
      <div className="mt-2">
        <label
          htmlFor="text"
          className="block text-sm font-medium leading-6 pb-1 text-gray-900"
        >
          API-nyckel
        </label>
        <input
          label="Shopify API-Key"
          type="text"
          id="apiKey"
          onChange={handleInputChange}
          value={inputValues.apiKey || ""}
          placeholder="Din API-nyckel"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div>
        <label
          htmlFor="domain"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Shopify-butikens domän
        </label>
        <div className="mt-2 flex rounded-md shadow-sm">
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
            http://
          </span>
          <input
            type="text"
            name="domain"
            onChange={handleInputChange}
            value={inputValues.domain || ""}
            id="domain"
            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="example.com"
          />
        </div>
      </div>
      <div className="mt-2">
        <label
          htmlFor="integrationName"
          className="block text-sm font-medium leading-6 pb-1 text-gray-900"
        >
          Benämning
        </label>
        <input
          label="Integration-name"
          type="text"
          id="integrationName"
          onChange={handleInputChange}
          value={inputValues.integrationName || ""}
          placeholder="Ge integrationen ett valfritt namn"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </>
  ),
  4: (
    <>
      <div className="mt-2">
        <p className="text-sm text-gray-900">
          Skapa API-nyckeln i Nyehandel under "Kontrollcenter" - "API". Tänk på
          att du även behöver en X-Identifier, som levereras via e-post.
        </p>
        <p className="mt-2 text-sm text-gray-500">
          När integrationen har validerats, behöver du även konfigurera en
          API-Webhook i Nyehandel. Klicka på Hantera i listan av integrationer
          för att göra detta.
        </p>
      </div>
      <div className="mt-2">
        <label
          htmlFor="text"
          className="block text-sm font-medium leading-6 pb-1 text-gray-900"
        >
          X-Identifier
        </label>
        <input
          type="text"
          id="apiKey2"
          onChange={handleInputChange}
          value={inputValues.apiKey2 || ""}
          placeholder="Din X-Identifer"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className="mt-2">
        <label
          htmlFor="text"
          className="block text-sm font-medium leading-6 pb-1 text-gray-900"
        >
          API-nyckel
        </label>
        <input
          type="text"
          id="apiKey"
          onChange={handleInputChange}
          value={inputValues.apiKey || ""}
          placeholder="Din API-nyckel"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className="mt-2">
        <label
          htmlFor="integrationName"
          className="block text-sm font-medium leading-6 pb-1 text-gray-900"
        >
          Benämning
        </label>
        <input
          label="Integration-name"
          type="text"
          id="integrationName"
          onChange={handleInputChange}
          value={inputValues.integrationName || ""}
          placeholder="Ge integrationen ett valfritt namn"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </>
  ),
  3: (
    <>
      <a
        href="https://admin.shopify.com/admin/oauth/authorize?client_id=2c2f96fa8ddf30afb90b166a29d203e3&redirect_uri=https://api.flowtrade.se/webhook/shopify/callback"
        rel="noopener noreferrer" // Förbättrar säkerheten för länkar som öppnas i nya flikar
        className="inline-block w-full text-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
      >
        Koppla Shopify-butik
      </a>
      <p className="text-sm text-gray-500 mt-2">
        Du blir omdirigerad till Shopify för att godkänna integrationen.
      </p>
    </>
  ),
  // Add more platforms and their inputs as needed
});

const divider = (
  <div className="relative">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex justify-center">
      <span className="bg-white px-2 text-sm text-gray-500">Fortsätt</span>
    </div>
  </div>
);

export default function AddIntegrationModal() {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const { setUpdateCount } = useUpdate();
  const [integrationData, setIntegrationData] = useState(null);

  const { updateIntegration, loading, error, success, resetError } =
    useIntegrationUpdate();

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setInputValues((prev) => ({ ...prev, [id]: value }));
    resetError();
  };

  const inputs = platformInputs(handleInputChange, inputValues)[
    selectedPlatform
  ];

  const handleSubmit = async (event) => {
    const formData = {
      ...inputValues,
      platformId: selectedPlatform,
    };

    try {
      await updateIntegration(formData);
      setUpdateCount((prevCount) => prevCount + 1);
    } catch (error) {
      toast.error(error.message || "Okänt fel");
    }
  };

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);

  const onCloseAndReset = () => {
    onClose(); // Anta att onOpen är din funktion för att öppna modalen
    setSelectedPlatform(null); // Återställer selectedPlatform state till null
    resetError();
  };

  return (
    <>
      <button
        onClick={onOpen}
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Ny integration
      </button>
      <Modal isOpen={isOpen} onClose={onCloseAndReset} placement="auto">
        <ModalContent>
          <ModalHeader className="...">Ny integration</ModalHeader>
          <ModalBody>
            <div className="flex justify-center">
              <Select
                label="Plattform"
                placeholder="Välj plattform"
                className="max-w"
                onChange={(e) => setSelectedPlatform(e.target.value)}
              >
                {platforms.map((platform) => (
                  <SelectItem
                    key={platform.value}
                    value={platform.value}
                    startContent={
                      <Avatar
                        alt={platform.label}
                        className="w-6 h-6"
                        src={platform.image}
                      />
                    }
                  >
                    {platform.label}
                  </SelectItem>
                ))}
              </Select>
            </div>
            {selectedPlatform && divider}
            {/* Render platform-specific input fields */}
            {selectedPlatform &&
              platformInputs(handleInputChange, inputValues)[selectedPlatform]}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="flat" onPress={onClose}>
              Avbryt
            </Button>
            <Button
              color="primary"
              onPress={handleSubmit}
              isLoading={loading}
              isDisabled={selectedPlatform === "3"}
            >
              Validera
            </Button>
          </ModalFooter>
          {error && <AddIntegrationMessage message={error} />}
        </ModalContent>
      </Modal>
    </>
  );
}
