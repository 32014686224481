import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts";

const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#8dd1e1"];

const renderCustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  courierName,
  serviceName,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = outerRadius + 20; // Placera utanför diagrammet
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      style={{ fontSize: "12px" }}
    >
      {`${courierName} - ${serviceName} (${(percent * 100).toFixed(0)}%)`}
    </text>
  );
};

const ShippingPieChart = ({ data }) => (
  <div style={{ width: "100%", height: "250px" }}>
    {" "}
    {/* Anpassa höjd och bredd */}
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          dataKey="count"
          nameKey="serviceName"
          label={renderCustomLabel}
          outerRadius="80%"
          labelLine={false}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default ShippingPieChart;
