import React from "react";
import { Skeleton } from "@nextui-org/react";

export default function TableSkeleton() {
  return (
    <div className="max-w-[300px] w-full flex items-center gap-3">
      <div>
        <Skeleton className="flex rounded-full w-12 h-12" />
      </div>
      <div className="w-full flex flex-col gap-2">
        <Skeleton className="h-3 w-3/5 rounded-lg" />
        <Skeleton className="h-3 w-4/5 rounded-lg" />
      </div>
    </div>
  );
}

export function OrderSkeleton() {
  return (
    <div className="pb-5 sm:flex sm:flex-col mx-auto max-w-5xl space-y-4 gap-y-6">
      {/* Top Section */}
      <div className="flex justify-between items-center">
        {/* Back Button Skeleton */}
        <Skeleton className="rounded-md h-8 w-10" animated />
        {/* Order Title and Details */}
        <div className="flex-grow ml-4">
          <Skeleton className="rounded-md h-4 w-48 mb-2" animated />
          <div className="flex items-center gap-2">
            <Skeleton
              className="hidden sm:block rounded-md h-2 w-24"
              animated
            />
            <Skeleton className="rounded-md h-2 w-16" animated />
          </div>
        </div>
        {/* Action Buttons */}
        <div className="flex space-x-4">
          <Skeleton className="hidden sm:block rounded-md h-8 w-24" animated />
          <Skeleton className="rounded-md h-8 w-24" animated />
          <Skeleton className="rounded-md h-8 w-10" animated />
        </div>
      </div>

      {/* Table Section */}
      <div className="overflow-hidden rounded-lg bg-white shadow border p-4">
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className="flex items-center justify-between border-b border-gray-100 py-3"
            >
              {/* Product Image */}
              <Skeleton
                className="h-12 w-12 rounded-full bg-gray-50"
                animated
              />
              {/* Product Info */}
              <div className="flex flex-col w-1/3 space-y-1">
                <Skeleton className="rounded-md h-4 w-full" animated />
                <Skeleton className="rounded-md h-4 w-3/4" animated />
              </div>
              {/* Quantity */}
              <Skeleton className="rounded-md h-4 w-1/6" animated />
              {/* Price */}
              <Skeleton
                className="hidden sm:block rounded-md h-4 w-1/6"
                animated
              />
              {/* Total */}
              <Skeleton
                className="hidden sm:block rounded-md h-4 w-1/6"
                animated
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export function SkeletonRow() {
  return (
    <tr className="animate-pulse">
      {/* Checkbox column */}
      <td className="relative px-7 sm:w-12 sm:px-6">
        <Skeleton className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded-full" />
      </td>
      {/* PlatformOrderId column */}
      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium">
        <Skeleton className="h-4 w-32 rounded-md" />
      </td>
      {/* Customer Name column */}
      <td className="whitespace-nowrap px-3 py-4">
        <Skeleton className="h-4 w-48 rounded-md mb-2" />
        <Skeleton className="h-4 w-36 rounded-md" />
      </td>
      {/* Customer Email column */}
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <Skeleton className="h-4 w-48 rounded-md" />
      </td>
      {/* Total Value column */}
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <Skeleton className="h-4 w-24 rounded-md" />
      </td>
      {/* Status column */}
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <Skeleton className="h-4 w-20 rounded-md" />
      </td>
      {/* Edit Button column */}
    </tr>
  );
}
