import { Fragment, useState, useEffect } from "react";
import { useGetOrder } from "../../contexts/orderHook";
import { useParams } from "react-router-dom";
import {
  CheckCircleIcon,
  ArrowLeftIcon,
  RocketLaunchIcon,
  PencilIcon,
  PlusIcon,
  EllipsisVerticalIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import OrderComments from "./comments";
import DeliverOrder from "../components/deliverOrderModal";
import ManageShipment from "../components/manageShipmentModal";
import ShareModal from "../components/shareOrderModal";
import TableSkeleton, { OrderSkeleton } from "./tableSkeleton";
import { Link } from "react-router-dom";
import { Dropdown, Tooltip } from "@nextui-org/react";
import { useUpdate } from "../../contexts/updateContext";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function formatDateTime(dateString) {
  // Kontrollera om dateString är giltig
  if (!dateString) {
    // Returnera ett standardvärde eller meddelande
    return "Laddar..."; // Eller returnera null eller '' om du föredrar det
  }

  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  // Använd 'sv-SE' eller annan lämplig locale beroende på önskat språk och format
  return new Intl.DateTimeFormat("sv-SE", options)
    .format(date)
    .replace(/\//g, "-");
}

function DeliverableChip({ status }) {
  let bgColor = "bg-blue-50";
  let textColor = "text-blue-700";
  let borderColor = "ring-blue-700/10";
  let statusMessage = "Redo";

  if (status === "sent") {
    bgColor = "bg-green-50";
    textColor = "text-green-700";
    borderColor = "ring-green-600/20";
    statusMessage = "Skickad";
  } else if (status === "partial") {
    bgColor = "bg-blue-50";
    textColor = "text-blue-700";
    borderColor = "ring-blue-600";
    statusMessage = "Dellevererad";
  } else if (status === "cancelled") {
    bgColor = "bg-red-50";
    textColor = "text-red-700";
    borderColor = "ring-red-600/20";
    statusMessage = "Avbruten";
  } else if (status === "refunded") {
    bgColor = "bg-yellow-50";
    textColor = "text-yellow-800";
    borderColor = "ring-yellow-600/20";
    statusMessage = "Återbetald";
  } else if (status === "external") {
    bgColor = "bg-yellow-50";
    textColor = "text-yellow-800";
    borderColor = "ring-yellow-600/20";
    statusMessage = "Extern";
  }

  return (
    <span
      className={`inline-flex ring-ye items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${borderColor}`}
    >
      {statusMessage}
    </span>
  );
}

function AdditionalChip({ status }) {
  let bgColor = "bg-gray-50";
  let textColor = "text-gray-600";
  let borderColor = "ring-gray-500/10";
  let statusMessage = "Okänd";

  if (status === "external") {
    bgColor = "bg-yellow-100";
    textColor = "text-yellow-800";
    statusMessage = "Extern";
  } else if (status === "shared") {
    bgColor = "bg-indigo-100";
    textColor = "text-indigo-700";
    statusMessage = "Delar";
  }

  return (
    <span
      className={`inline-flex items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}
    >
      {statusMessage}
    </span>
  );
}

function DropdownMenu({ setOpenState, status, orderData }) {
  const [deliverModalOpen, setDeliverModalOpen] = useState(false);

  useEffect(() => {
    if (deliverModalOpen === true) {
      setDeliverModalOpen(false);
    }
  }, [deliverModalOpen]);

  return (
    <>
      <DeliverOrder
        orderData={orderData}
        button="create"
        buttonStyle="invisible"
        deliverModalOpen={deliverModalOpen}
      />
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex flex-col-2 gap-x-1">
          <MenuButton className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon aria-hidden="true" className="h-6 w-6" />
          </MenuButton>
          <p className="text-sm leading-6 text-gray-700">Mer</p>
        </div>

        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <MenuItem>
              <button
                onClick={() => setOpenState(true)}
                className="block w-full px-4 py-2 text-sm text-left text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
                Dela med extern miljö
              </button>
            </MenuItem>
            {status === "sent" && (
              <MenuItem>
                <button
                  onClick={() => setDeliverModalOpen(true)}
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                >
                  Skapa frakt
                </button>
              </MenuItem>
            )}
          </div>
        </MenuItems>
      </Menu>
    </>
  );
}

function Navigation({ orderId, handleNavigate }) {
  const [isNavigating, setIsNavigating] = useState(false);

  const handleNavigatePrevious = () => {
    const sequence = JSON.parse(localStorage.getItem("orderSequence")) || [];
    const currentIndex = sequence.indexOf(orderId);

    if (currentIndex > 0) {
      const previousOrderId = sequence[currentIndex - 1];
      handleNavigate(previousOrderId);
    }
  };

  const handleNavigateNext = () => {
    const sequence = JSON.parse(localStorage.getItem("orderSequence")) || [];
    const currentIndex = sequence.indexOf(orderId);

    if (currentIndex >= 0 && currentIndex < sequence.length - 1) {
      const nextOrderId = sequence[currentIndex + 1];
      handleNavigate(nextOrderId);
    }
  };

  // Kontrollera var i sekvensen vi är
  const sequence = JSON.parse(localStorage.getItem("orderSequence")) || [];
  const currentIndex = sequence.indexOf(orderId);
  const isFirstOrder = currentIndex <= 0;
  const isLastOrder = currentIndex === sequence.length - 1;

  return (
    <div className="flex items-center justify-between px-4 py-3 sm:px-6">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <nav
          aria-label="Pagination"
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
        >
          {/* Previous Button */}
          <button
            onClick={handleNavigatePrevious}
            disabled={isFirstOrder || isNavigating}
            className={`relative inline-flex items-center rounded-l-md px-2 py-2 ${
              isFirstOrder || isNavigating
                ? "bg-gray-200 text-gray-400"
                : "bg-white text-gray-400 hover:bg-gray-50"
            } ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0`}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon aria-hidden="true" className="w-5 h-5" />
          </button>

          {/* Next Button */}
          <button
            onClick={handleNavigateNext}
            disabled={isLastOrder || isNavigating}
            className={`relative inline-flex items-center rounded-r-md px-2 py-2 ${
              isLastOrder || isNavigating
                ? "bg-gray-200 text-gray-400"
                : "bg-white text-gray-400 hover:bg-gray-50"
            } ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0`}
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon aria-hidden="true" className="w-5 h-5" />
          </button>
        </nav>
      </div>

      {/* Laddningsindikator */}
      {isNavigating && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-50"></div>
      )}
    </div>
  );
}

function ShipmentsTable({ loading, orderData }) {
  if (loading) {
    return <TableSkeleton />;
  }

  return (
    <table className="min-w-full divide-y divide-gray-100">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
          >
            Sändningsnummer
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
          >
            Typ
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Tjänst
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-100">
        {orderData?.shipments && orderData.shipments.length > 0 ? (
          orderData.shipments
            .slice()
            .reverse()
            .map((shipment, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-600">
                  <Tooltip
                    content="Klicka för att spåra"
                    placement="bottom"
                    closeDelay="100"
                  >
                    <a
                      href={shipment.trackingUrl || ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shipment.shipmentId}
                    </a>
                  </Tooltip>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span
                    className={
                      shipment.returnShipment === false
                        ? "inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
                        : "inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800"
                    }
                  >
                    <svg
                      className={
                        shipment.returnShipment === false
                          ? "h-1.5 w-1.5 fill-green-500"
                          : "h-1.5 w-1.5 fill-yellow-500"
                      }
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                    {shipment.returnShipment === false ? "Utleverans" : "Retur"}
                  </span>
                </td>
                <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {shipment.courierName}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-600">
                  <ManageShipment orderData={orderData} shipment={shipment} />
                </td>
              </tr>
            ))
        ) : (
          <tr>
            <td
              colSpan="4"
              className="text-center text-sm text-gray-500 px-3 py-4"
            >
              Det finns inga frakthandlingar för denna beställning
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default function Order() {
  const { orderId } = useParams();
  const {
    loading: apiLoad,
    error,
    orderData,
    setOrderData,
  } = useGetOrder(orderId);
  const { setUpdateCount } = useUpdate();
  const [openState, setOpenState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const handleNavigate = (targetOrderId) => {
    setLoading(true); // Starta mellanladdning
    setOrderData(null); // Nollställ orderData för att trigga skeleton
    navigate(`/dash/orders/${targetOrderId}`);
  };

  useEffect(() => {
    // Sätt `loading` till false endast när API-laddning är klar och data finns
    if (apiLoad === false && orderData) {
      setLoading(false);
    }
  }, [apiLoad, orderData]);

  //   useEffect(() => {
  //     // Sätt `loading` till false efter 2 sekunder
  //     const timer = setTimeout(() => {
  //       setLoading(false);
  //     }, 1500);

  //     return () => clearTimeout(timer);
  //   }, []);

  useEffect(() => {
    console.log("open state", openState);
  }, [openState]);

  const activity = orderData?.activity;

  const handleUpdate = async () => {
    setUpdateCount((prevCount) => prevCount + 1);
  };

  const navigate = useNavigate();

  const isSameAddress =
    orderData?.shippingAddress.firstName ===
      orderData?.billingAddress.firstName &&
    orderData?.shippingAddress.lastName ===
      orderData?.billingAddress.lastName &&
    orderData?.shippingAddress.address1 ===
      orderData?.billingAddress.address1 &&
    orderData?.shippingAddress.zip === orderData?.billingAddress.zip &&
    orderData?.shippingAddress.city === orderData?.billingAddress.city &&
    orderData?.shippingAddress.countryCode ===
      orderData?.billingAddress.countryCode;

  return (
    <>
      {loading === false ? (
        <>
          <ShareModal
            openState={openState}
            setOpenState={setOpenState}
            orderId={orderData?._id}
            externalUserIds={orderData?.externalUserIds}
            owned={orderData?.owned}
          />
          <div className="pb-5 sm:flex sm:items-center sm:justify-between mx-auto max-w-5xl">
            <div className="mt-3 flex sm:mt-0 items-center sm:flex-grow">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <ArrowLeftIcon
                  className="-mr-1 h-5 w-10 text-gray-900"
                  aria-hidden="true"
                />
              </button>
              <div className="ml-4">
                <h3 className="text-xl font-bold leading-7 text-gray-900">
                  Beställning {orderData?.platformOrderId}
                </h3>
                <div className="flex items-center gap-2">
                  <p className="text-sm text-gray-500">
                    {formatDateTime(orderData?.createdAt)}
                  </p>
                  <DeliverableChip status={orderData?.status} />
                  {orderData?.external && !orderData?.owned && (
                    <AdditionalChip status="external" />
                  )}
                  {orderData?.external && orderData?.owned && (
                    <AdditionalChip status="shared" />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3 flex items-center sm:ml-4 sm:mt-0">
              <button
                type="button"
                onClick={handleUpdate}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Avbryt beställning
              </button>
              {(orderData?.status === "open" ||
                orderData?.status === "partial") && (
                <DeliverOrder
                  orderData={orderData}
                  button="deliver"
                  buttonStyle="deliver"
                />
              )}
              <Navigation orderId={orderId} handleNavigate={handleNavigate} />
              <DropdownMenu
                setOpenState={setOpenState}
                status={orderData?.status}
                orderData={orderData}
              />
            </div>
          </div>
          <div className="flex justify-end max-w-5xl"></div>
          <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 mt-4 mx-auto max-w-5xl items-start">
            <div className="w-full sm:basis-3/5">
              <div className="overflow-hidden rounded-lg bg-white shadow border mb-4 sm:mb-0">
                <div className="bg-white px-4 py-5 sm:p-6">
                  {/* Content goes here */}
                  <div className="-mx-4 flow-root sm:mx-0">
                    <table className="min-w-full">
                      <colgroup>
                        <col className="sm:w-1/6" />
                        <col className="sm:w-1/3" />
                        <col className="sm:w-1/6" />
                        <col className="sm:w-1/6" />
                        <col className="sm:w-1/6" />
                      </colgroup>
                      <thead className="border-b border-gray-100 text-gray-900">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          ></th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Artikel
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Antal
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Á-pris
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0"
                          >
                            Summa
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData?.products?.map((product, index) => (
                          <tr key={index} className="border-b border-gray-100">
                            <td className="">
                              <img
                                className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover"
                                src={product.imageSrc}
                                alt=""
                              />
                            </td>
                            <td className="max-w-0 py-5 pr-3 text-sm sm:pl-0">
                              <div className="font-medium text-gray-900">
                                {product.title}
                              </div>
                              <div className="mt-1 text-gray-500">
                                Artnr. {product.sku}
                              </div>
                            </td>
                            {product.quantity >= 2 ? (
                              <td className="px-3 py-5 text-right text-sm font-bold text-gray-500 sm:table-cell">
                                <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-sm font-medium text-red-700">
                                  {product.quantity} x
                                </span>
                              </td>
                            ) : (
                              <td className="px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                                {product.quantity} x
                              </td>
                            )}

                            <td className="px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                              {product.price} {orderData?.currency}
                            </td>
                            <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                              {(product.quantity * product.price).toFixed(2)}{" "}
                              {orderData?.currency}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th
                            scope="row"
                            colSpan={4}
                            className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                          >
                            Delsumma
                          </th>
                          <th
                            scope="row"
                            colSpan={4}
                            className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                          >
                            Delsumma
                          </th>
                          <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-0">
                            {orderData?.subtotal_price} {orderData?.currency}
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            colSpan={4}
                            className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                          >
                            Fraktkostnad
                          </th>
                          <th
                            scope="row"
                            colSpan={4}
                            className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                          >
                            Fraktkostnad
                          </th>
                          <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                            {orderData?.total_shipping_price}{" "}
                            {orderData?.currency}
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            colSpan={4}
                            className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                          >
                            Totalt
                          </th>
                          <th
                            scope="row"
                            colSpan={4}
                            className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                          >
                            Totalt
                          </th>
                          <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                            {orderData?.total_price} {orderData?.currency}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                {/* Container div end 1 */}
              </div>{" "}
              {/*Plats för fler höger-containers under denna div*/}
              {orderData?.shipments && orderData.shipments.length > 0 && (
                <div className="overflow-hidden rounded-lg bg-white shadow border mb-4 mt-4 sm:mb-0">
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex items-center">
                      <div className="flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                          Leveranser
                        </h1>
                      </div>
                      <div className="ml-16 mt-0 flex-none">
                        <DeliverOrder
                          orderData={orderData}
                          button="create"
                          buttonStyle="create"
                        />
                      </div>
                    </div>
                    <div>
                      <ShipmentsTable loading={loading} orderData={orderData} />
                    </div>
                  </div>
                </div>
              )}
              <OrderComments
                orderId={orderId}
                orderData={orderData}
                setOrderData={setOrderData}
              />
            </div>
            <div className="w-full overflow-hidden rounded-lg bg-white border shadow sm:basis-2/5">
              <div className="px-4 py-5 sm:px-6">
                {/* Content goes here */}
                <div className="px-4 sm:px-0">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    Beställare
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 text-blue-500">
                    {orderData?.customer.firstName}{" "}
                    {orderData?.customer.lastName}
                  </p>
                </div>
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Kontakt
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <dl>
                          <div>
                            <dd>{orderData?.customer.phone}</dd>
                          </div>
                          <div>
                            <dd className="text-blue-500">
                              {orderData?.customer.email}
                            </dd>
                          </div>
                        </dl>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Leveransadress
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <dl>
                          {orderData?.shippingAddress.company && (
                            <div>
                              <dd>{orderData?.shippingAddress.company}</dd>
                            </div>
                          )}
                          <div>
                            <dd>
                              {orderData?.shippingAddress.firstName}{" "}
                              {orderData?.shippingAddress.lastName}
                            </dd>
                          </div>
                          <div>
                            <dd>{orderData?.shippingAddress.address1}</dd>
                          </div>
                          <div>
                            <dd>
                              {orderData?.shippingAddress.zip}{" "}
                              {orderData?.shippingAddress.city}
                            </dd>
                          </div>
                          <div>
                            <dd>{orderData?.shippingAddress.countryCode}</dd>
                          </div>
                        </dl>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Fakturaadress
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {isSameAddress ? (
                          <span>Samma som ovan</span>
                        ) : (
                          <dl>
                            {orderData?.billingAddress.company && (
                              <div>
                                <dd>{orderData?.billingAddress.company}</dd>
                              </div>
                            )}
                            <div>
                              <dd>
                                {orderData?.billingAddress.firstName}{" "}
                                {orderData?.billingAddress.lastName}
                              </dd>
                            </div>
                            <div>
                              <dd>{orderData?.billingAddress.address1}</dd>
                            </div>
                            <div>
                              <dd>
                                {orderData?.billingAddress.zip}{" "}
                                {orderData?.billingAddress.city}
                              </dd>
                            </div>
                            <div>
                              <dd>{orderData?.billingAddress.countryCode}</dd>
                            </div>
                          </dl>
                        )}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Detaljer
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <dl>
                          <div>
                            <dt className="font-medium">Fraktsätt</dt>
                            <dd>{orderData?.shipmentMethod || "Okänt"}</dd>
                          </div>
                          <div>
                            <dt className="font-medium">Integration</dt>
                            <dd>{orderData?.integrationName}</dd>
                          </div>
                        </dl>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>{" "}
              {/*customer div end */}
            </div>
          </div>
        </>
      ) : (
        <OrderSkeleton />
      )}
    </>
  );
}
